import Swal from "sweetalert2";
export default {
  name: "EditAdd",
  created() {
    this.GetInfo(1);
    this.form.Id = this.$parent.EditAddItem?._id;
    this.form.keyWords = this.$parent.EditAddItem?.keyWords;
    this.form.slogan = this.$parent.EditAddItem?.slogan;
    this.form.Name = this.$parent.EditAddItem?.Name;
    this.form.CompleteHoures = this.$parent.EditAddItem?.CompleteHoures;
    this.form.Description = this.$parent.EditAddItem?.Description;
    this.form.PackageFullPrice = this.$parent.EditAddItem?.PackageFullPrice;
    if (this.$parent.EditAddItem) {
      this.form.IsLifeTime = this.$parent.EditAddItem?.IsLifeTime.toString();
      this.form.StudentRange = this.$parent.EditAddItem?.StudentRange;
    }
    this.form.PhotoFullPath = this.$parent.EditAddItem?.PhotoFullPath;
    this.form.Category = this.$parent.EditAddItem?.Category?._id;
    setTimeout(() => {
      this.form.SubCategory = this.$parent.EditAddItem?.SubCategory?._id;
    }, 1000);
    if (this.$parent.EditAddItem?.SubjectGoals) {
      this.form.SubjectGoals = JSON.parse(
        this.$parent.EditAddItem?.SubjectGoals
      );
    }
    if (this.$parent.EditAddItem?.requirement) {
      this.form.requirement = JSON.parse(this.$parent.EditAddItem?.requirement);
    }
  },
  data() {
    return {
      goal: "",
      activeNames: "",
      Info: [],
      ruleForm: {},
      rules: {},
      Categories: [],
      goal: "",
      SubCategories: [],
      Storage: localStorage.getItem("transmission"),
      form: {
        keyWords: "",
        requirement: [],
        SubjectGoals: [],
        StudentRange: [
          { from: 0, to: 0, percentage: 0 },
          { from: 0, to: 0, percentage: 0 },
          { from: 0, to: 0, percentage: 0 },
        ],
        IsLifeTime: "false",
        slogan: "",
        Category: "",
        Name: "",
        CompleteHoures: "",
        Description: "",
        PackageFullPrice: "",
        SubCategory: "",
        PhotoFullPath: "",
      },
    };
  },
  watch: {
    "form.Category"() {
      this.form.SubCategory = "";
      if (this.form.Category) {
        this.GeActiveSubCategory();
      }
    },
  },
  methods: {
    addGoal() {
      if (!this.goal) {
        this.$message({
          type: "error",
          message: "الرجاء ادخال الهدف الخاص بالكورس",
        });
        return;
      }
      this.form.SubjectGoals.push({ Name: this.goal });
      return (this.goal = "");
    },
    addRequirement() {
      if (!this.requirement) {
        this.$message({
          type: "error",
          message: "الرجاء ادخال المتطلبات الخاص بالكورس",
        });
        return;
      }
      this.form.requirement.push({ Name: this.requirement });
      return (this.requirement = "");
    },

    handleExceed() {},
    onBeforeUpload() {},
    beforeRemove() {},
    handleRemoveFile() {},
    async GeActiveSubCategory() {
      this.SubCategories = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveSubCategory(this.form.Category, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.SubCategories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
        });
    },
    async GetInfo() {
      this.Info = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      // this.$http
      //   .GetTeachers(1, 200, reCAPTCHA, false, "")
      //   .then((response) => {
      //     this.$blockUI.Stop();
      //     this.Info = response.data.info;
      //   })
      //   .catch((error) => {
      //     if (error.response.status == 401) {
      //       return  window.location = "/"
      //     }
      //     this.pages = 0;
      //   });
      await this.$recaptchaLoaded();
      reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveCategory(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Categories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    Back() {
      this.$parent.state = 0;
    },
    Delete(index) {
      Swal.fire({
        title: "هل انت متأكد  من الحذف؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.form.SubjectGoals.splice(index, 1);
        }
      });
    },
    DeleteRequirement(index) {
      Swal.fire({
        title: "هل انت متأكد  من الحذف؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.form.requirement.splice(index, 1);
        }
      });
    },

    FileChanged(e) {
      this.form.PhotoFullPath = e.raw;
    },
    async Save() {
      this.$blockUI.Start();
      if (
        !this.form.Name ||
        !this.form.CompleteHoures ||
        !this.form.Description ||
        !this.form.PackageFullPrice ||
        !this.form.SubCategory ||
        !this.form.PhotoFullPath
      ) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      if (this.form.SubjectGoals.length == 0) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء تعبئة اهداف الكورس ",
        });
        return;
      }
      if (this.form.requirement.length == 0) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء تعبئة المتطلبات الخاصة الكورس ",
        });
        return;
      }
      if (!this.form.slogan) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء وضع عبارة التسويقية لا تتجاوز الـ 70 حرف ",
        });
        return;
      }
      if (!this.form.slogan.length > 70) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء وضع عبارة التسويقية لا تتجاوز الـ 70 حرف ",
        });
        return;
      }
      //

      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        let form = new FormData();
        form.append("Name", this.form.Name);
        form.append("slogan", this.form.slogan);
        form.append("SubjectGoals", JSON.stringify(this.form.SubjectGoals));
        form.append("requirement", JSON.stringify(this.form.requirement));
        form.append("CompleteHoures", this.form.CompleteHoures);
        form.append("keyWords", this.form.keyWords);
        form.append("Description", this.form.Description);
        form.append("PackageFullPrice", this.form.PackageFullPrice);
        form.append("IsLifeTime", this.form.IsLifeTime);
        form.append("SubCategory", this.form.SubCategory);
        form.append("PhotoFullPath", this.form.PhotoFullPath);
        form.append("Category", this.form.Category);
        form.append("StudentRange", JSON.stringify(this.form.StudentRange));
        return this.$http
          .updateSubject(this.$parent.EditAddItem._id, form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;

            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      let form = new FormData();
      form.append("Name", this.form.Name);
      form.append("CompleteHoures", this.form.CompleteHoures);
      form.append("Description", this.form.Description);
      from.append("keyWords", this.form.keyWords);
      form.append("PackageFullPrice", this.form.PackageFullPrice);
      form.append("SubjectGoals", JSON.stringify(this.form.SubjectGoals));
      form.append("requirement", JSON.stringify(this.form.requirement));
      form.append("slogan", this.form.slogan);
      // form.append("Teacher", this.form.Teacher);
      form.append("IsLifeTime", this.form.IsLifeTime);
      form.append("StudentRange", JSON.stringify(this.form.StudentRange));
      form.append("SubCategory", this.form.SubCategory);
      form.append("PhotoFullPath", this.form.PhotoFullPath);
      form.append("Category", this.form.Category);
      this.$http
        .AddSubject(form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;

          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
