export default {
  name: "EditAdd",
  created() {
    this.GetCategories();
    this.form.Id = this.$parent.EditAddItem?._id;
    this.form.Name = this.$parent.EditAddItem?.Name;
    this.form.Category = this.$parent.EditAddItem?.Category?._id;
  },
  data() {
    return {
      Categories:[],
      form: {
        Name: "",
        Id: "",
        Category: "",
      },
    };
  },
  methods: {
    async GetCategories() {
      this.Categories = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveCategory(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Categories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
        });
    },
    Back() {
      this.$parent.state = 0;
    },
    async Save() {
      this.$blockUI.Start();
      if (!this.form.Name) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال الاسم ",
        });
        return;
      }
      if (!this.form.Category) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال التصنيف ",
        });
        return;
      }

      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        return this.$http
          .updateSubCategory(this.$parent.EditAddItem._id, this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      this.$http
        .AddSubCategory(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
