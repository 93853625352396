import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  created() {
    this.GetInfo();
    this.GetActiveCategory();
  },
  components: {},
  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      form: {
        Category: null,
        order: null,
        Subjects: [],
      },
      inRoll: null,
      rate: null,
      price: null,
      oldPrice: null,
      categoryName: "",
      id: "",
      Subject: null,
      AddSubjects: false,
      Category: null,
      Subjects: [],
      SubCategory: "",
      SubCategories: "",
      Categories: [],
      dialogVisible: false,
      phoneNumber: "",
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Status: false,
      EditAddItem: [],
    };
  },
  methods: {
    deleteRow(index) {
      Swal.fire({
        title: "هـل انت متأكد من الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.form.Subjects.splice(index, 1);
          this.SaveEdit();
        }
      });
    },
    SubCategoryChange() {
      if (this.SubCategory) {
        this.Subject = "";
        this.GetSubjects(1);
      }
    },
    CategoryChange() {
      this.SubCategory = "";
      if (this.Category) {
        this.GeActiveSubCategory();
      }
    },
    async SaveEdit() {
      if (this.form.Subjects.length == 0) {
        this.$message({
          type: "error",
          message: "الرجاء تعبئة عنصر واحد على الاقل",
        });
        return;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.id) {
        return this.$http
          .updateVIPList(this.id, this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.GetInfo(this.pageNo);
            this.AddSubjects = false;
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
    },
    async Save() {
      this.$blockUI.Start();
      if (!this.form.Category || !this.form.order) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .CreateVIP(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.GetInfo(1);
          this.dialogVisible = false;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          console.log(err);
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    AddVIP() {
      if (!this.inRoll || !this.rate || !this.price || !this.oldPrice) {
        return this.$message({
          type: "error",
          message: "عذرا الرجاء تعبئة جميع الحقول",
        });
      }
      if (this.rate>5 || this.rate<1) {
        return this.$message({
          type: "error",
          message: "الرجاء التأكد من التقيم يجب ان يكون مطابق للحالي",
        });  
      }
      if (this.oldPrice<this.price) {
        return this.$message({
          type: "error",
          message: "الرجاء التأكد من الاسعار",
        }); 
      }
      if (this.oldPrice==this.price) {
        return this.$message({
          type: "error",
          message: "الرجاء التأكد من الاسعار",
        }); 
      }
      if (!this.Subject) {
        return this.$message({
          type: "error",
          message: "عذرا الرجاء اختيار المادة",
        });
      }
      let tempSubject;
      this.Subjects.forEach((element) => {
        if (element._id == this.Subject) {
          tempSubject = element;
        }
      });

      if (!tempSubject) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة تحميل الصفحة",
        });
      }
      let isExisted = false;
      this.form.Subjects.forEach((ele) => {
        if (ele._id == tempSubject._id) {
          isExisted = true;
        }
      });
      if (isExisted) {
        return this.$message({
          type: "error",
          message: "المادة موجودة مسبقاً",
        });
      }

      this.form.Subjects.push({
        inRoll: this.inRoll,
        rate: this.rate,
        price: this.price,
        oldPrice: this.oldPrice,
        _id: tempSubject._id,
        Name: tempSubject.Name,
        CompleteHoures: tempSubject.CompleteHoures,
        Description: tempSubject.Description,
        PhotoFullPath: tempSubject.PhotoFullPath,
        Teacher: tempSubject.Teacher.Name,
        Teacher_id: tempSubject.Teacher._id,
      });
      this.SaveEdit();
    },
    async GetSubjects(pageNo = 1) {
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetSubject(this.pageNo, 1000, reCAPTCHA, this.SubCategory, false)
        .then((response) => {
          this.$blockUI.Stop();
          this.Subjects = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.pages = 0;
        });
      await this.$recaptchaLoaded();
      reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveCategory(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Categories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        });
    },
    async GeActiveSubCategory() {
      this.SubCategories = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveSubCategory(this.Category, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.SubCategories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
        });
    },
    async GetActiveCategory() {
      this.$blockUI.Start();
      let reCAPTCHA = await this.$recaptchaLoaded();
      reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveCategory(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Categories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        });
    },
    async GetInfo(pageNo) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetVIPList(this.pageNo, this.pageSize, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },

    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من ايقاف تفعيل ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .CategoryChangeStatus(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envTeachers() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  الحذف سيتم حذف السجل ولا يمكن استرجاعه ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .DeleteVIPList(Id, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    Edit(item) {
      this.id = item._id;
      this.AddSubjects = true;
      this.Category = item.Category._id;
      this.form.order = item.order;
      this.categoryName = item.Category.Name;
      this.form.Subjects = item.Subjects;
      this.CategoryChange();
    },

    Addpage() {
      this.dialogVisible = true;
      this.form.Category = null;
      this.form.order = null;
    },
  },
};
