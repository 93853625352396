import Add_Edit from "./Add_Edit/Add_Edit.vue";
import video from "./Vedio_index/index.vue";

import Swal from "sweetalert2";
import moment from "moment";

export default {
  name: "Index",
  created() {
    // this.GetSubjects();
    // this.GeActiveCategory();
    this.Subject = this.$route.params.id

    this.GetInfo(1)
  },

  components: {
    "Add-Edit": Add_Edit,
    "video-index": video,
  },

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      srcImage:this.$http.envUnit(),
      Categories: [],
      SubCategories: [],
      Subjects: [],
      Subject: '',
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Category: "",
      SubCategory: "",
      EditAddItem: [],
      Status: true,
    };
  },
  watch: {
    Status() {
      this.GetInfo(1);
    },
    // Category() {
    //   if (this.Category) {
    //     this.GeActiveSubCategory();
    //   }
    //   this.SubCategory = "";
    //   this.Subject = "";
    // },
    // SubCategory() {
    //   this.Subject = "";
    // },
    // Subject() {
    //   this.GetInfo(1);
    // },
  },
  methods: {
    minsss(date) {
      let da = new Date(date);
      return da.getHours() + ":" + da.getMinutes() + ":" + da.getSeconds();
    },
    handleClose(){},
    Back(){
      this.$router.push('/Subject')
    },

    async GetInfo(pageNo) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetUnits(
          this.pageNo,
          this.pageSize,
          reCAPTCHA,
          // this.SubCategory,
          this.Status,
          this.Subject
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
          this.Subjects = response.data.Subjects;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          this.$blockUI.Stop();
          if (error.response.status == 401) {
            return this.$router.replace('/login')
          }
         
          this.pages = 0;
        });
    },
    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من تعطيل النشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeUnitsStatus(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envUnit() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  نشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeUnitsStatus(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    Edit(item) {
      this.state = 2;
      this.EditAddItem = item;
    },

    EditSubject(item) {
      this.state = 3;
      this.EditAddItem = item;
    },

    Addpage() {
      this.state = 2;
      this.EditAddItem = null;
    },














    // async GeActiveSubCategory() {
    //   this.SubCategories = [];
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .GeActiveSubCategory(this.Category, reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.SubCategories = response.data.info;
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //     });
    // },
    // async GeActiveCategory() {
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .GeActiveCategory(reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.Categories = response.data.info;
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //       this.$blockUI.Stop();
    //     });
    // },
    // async GetSubjects() {
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .AllSubjects(this.SubCategory, reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.Subjects = response.data.info;
    //       if (this.Subjects.length == 1) {
    //         this.Subject = this.Subjects[0]._id;
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //       this.$blockUI.Stop();
    //     });
    // },
  },
};
