export default {
  name: "appHeader",
  created() {
    if (localStorage.getItem("image")) {
      this.image = localStorage.getItem("image");
    }
    window.scrollTo(0, 0);
  },
  data() {
    return {
      ImageSrc:this.$http.envTeachers(),
      image:'',
      name: localStorage.getItem('name'),
      loginDetails: null,
      transmission:localStorage.getItem("transmission"),
      active: 1,
      menuFlag: [20],
      permissions: JSON.parse(localStorage.getItem('group')),
    };
  },
  methods: {
    Logout() {
      localStorage.clear();
      return  window.location = "/";;
    },
    href(url) {
      this.$router.push(url);
    },
    // CheckLoginStatus() {
    //   try {
    //     this.loginDetails = JSON.parse(
    //       localStorage.getItem("currentUser-client")
    //     );
    //     if (this.loginDetails == null) {
    //       window.location.href = "/Login";
    //     }
    //   } catch (error) {
    //     window.location.href = "/Login";
    //   }
    // },

    openNav() {
      document.getElementById("mySidebar").style.width = "250px";
      document.getElementById("main").style.marginLeft = "250px";
    },

    /* Set the width of the sidebar to 0 and the left margin of the page content to 0 */
    closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("main").style.marginLeft = "0";
    },
  },
};
