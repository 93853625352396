import Swal from "sweetalert2";
import moment from "moment";
// import websocketStream from "websocket-stream";
import { useProgressStore } from "../../../../Shared/store.js";

import soundPath from "../../../../assets/notifi.mp3";

export default {
  name: "Index",

  created() {
    this.GetVideo();
  },
  components: {},

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("HH:MM:SS");
    },
  },

  data() {
    return {
      saveStatus: false,
      commentText: "",
      commentDialog: false,
      comments: [],
      socket: "",
      progress: 0,
      QuestionsLink: process.env.VUE_APP_URL + "images/QuestionsImages/",
      activeNames: "",
      QuestionsDialog: false,
      title: "محاضرة",
      ExamDialog: false,
      filePath: "",
      FileId: "",
      FileInfo: [],
      videoDialog: false,
      FilesDialog: false,
      Subjects: [],
      ruleForm: {},
      rules: {},
      Name: "",
      FileName: "",
      videoLink: "",
      IsTest: false,
      Id: "",
      answer: "",
      IsCorrectAnswer: "false",
      Questions: {
        Name: "",
        answers: [],
        order: "",
        file: "",
        pageNo: 1,
        pageSize: 5,
        pages: 0,
      },
      SavedQuestions: [],
      Exams: {
        Name: "",
        order: "",
        description: "",
        CanPass: "true",
        IsTest: true,
      },
      fileForm: {
        file: "",
        Name: "",
        order: "",
        description: "",
        pageNo: 1,
        pageSize: 5,
        pages: 0,
      },
      form: {
        pageNo: 1,
        pageSize: 5,
        pages: 0,
        file: "",
        Name: "",
        time: "",
        State: false,
        order: "",
        CanPass: "true",
        description: "",
        IsTest: false,
      },
      QuestionsInfo: [],
      QuestionsId: "",
      QuestionId: "",
      questionsTempFile: "",
      editFileId: "",
      Subject: "",
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      state: 0,
      Category: false,
      YearOfStudy: 3,
      EditAddItem: [],
      Status: true,
      commentId: "",
      replayId: "",
      replayState: "",
      commentReplay: "",
    };
  },
  watch: {},
  methods: {
    minsss: function (date) {
      let da = new Date(date);
      return da.getHours() + ":" + da.getMinutes() + ":" + da.getSeconds();
    },
    deleteReplay(id) {
      Swal.fire({
        title: `هل انت متأكد من حذف الرد`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .deleteReplay(id, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetComment(this.commentId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    deleteComment(id) {
      Swal.fire({
        title: `هل انت متأكد من حذف التعليق`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .deleteComment(id, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetComment(this.commentId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    openReplay(item) {
      this.replayId = item._id;
      this.replayState = true;
      this.commentReplay = item.text;
    },
    CancelREplay() {
      this.commentText = "";
      this.replayId = "";
      this.commentReplay = "";
      this.replayState = false;
    },
    async TeacherReplay() {
      try {
        if (!this.commentText || !this.replayId) {
          return this.$message({
            type: "error",
            message: "الرجاء ادخال تعليق",
          });
        }
        this.$blockUI.Start();
        let schema = {
          text: this.commentText,
        };
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        this.$http
          .TeacherReplay(this.replayId, schema, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "success",
              message: response.data.Message,
            });
            this.commentText = "";
            this.replayId = "";
            this.commentReplay = "";
            this.replayState = false;
            this.GetComment(this.commentId);
            return;
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      } catch (error) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة المحاولة ",
        });
      }
    },
    async SendComment() {
      try {
        if (!this.commentText) {
          return this.$message({
            type: "error",
            message: "الرجاء ادخال تعليق",
          });
        }
        this.$blockUI.Start();
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        let schema = {
          text: this.commentText,
          video: this.commentId,
        };
        this.$http
          .TeacherComment(schema, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "success",
              message: response.data.Message,
            });
            this.commentText = "";
            this.GetComment(this.commentId);
            return;
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      } catch (error) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة المحاولة ",
        });
      }
    },

    async GetComment(item) {
      this.commentId = item;
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      this.comments = [];
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetComments(item, reCAPTCHA)
        .then((response) => {
          this.commentDialog = true;
          this.comments = response.data.info;
          this.$blockUI.Stop();
          return;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },

    readChunkAsArrayBuffer(chunk) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = function (event) {
          resolve(event.target.result);
        };

        reader.onerror = function (event) {
          reject(event.error);
        };

        reader.readAsBinaryString(chunk);
      });
    },

    async startUpload(file, Name) {
      let chunkSize = 1024 * 700;
      let offset = 0;
      Math.ceil(file.size / chunkSize);
      while (offset < file.size) {
        const chunk = file.slice(offset, offset + chunkSize);
        const arrayBuffer = await this.readChunkAsArrayBuffer(chunk);
        let formData = {
          Name: Name,
          chunk: arrayBuffer,
          size: file.size,
        };
        try {
          let result = await this.uploadChunk(formData, Name, offset);
          if (result == false) {
            return;
          }
        } catch (error) {}
        offset += chunkSize;
        let result = Math.min(((offset / file.size) * 100).toFixed(0), 100);
        useProgressStore().UpdateUploadProgress({
          progress: result,
          id: Name,
        });
      }
      new Audio(soundPath).play();
      this.$blockUI.Stop();
      return this.$notify({
        type: "success",
        title: "تم تحميل الملف بنجاح",
        position: "top-left",
      });
      // let uploadStrategies = localStorage.getItem("fileUploadStrategies");
      // let files = JSON.parse(uploadStrategies);
      // let index = files.indexOf(Name);
      // files.splice(index, 1);
      // console.log("File upload complete.");
    },
    async uploadChunk(formData, Name, offset) {
      try {
        await this.$http.uploadFile(formData);
      } catch (error) {
        console.log(error);
        return false;
      }
    },
    async uploadFile(name) {
      try {
        const file = this.form.file;
        let Name = name;
        useProgressStore().setUploadProgress({
          progress: 0,
          fileName: this.form.file.name,
          id: Name,
        });
        if (!file) {
          return;
        }
        return this.startUpload(file, Name);
      } catch (error) {
        console.log(error);
      }
    },
    Back() {
      this.$parent.state = 0;
    },
    async UpdateQuestion() {
      try {
        if (!this.QuestionId) {
          return this.$message({
            type: "error",
            message:
              "عذراً. حدث خطأ اثناء جلب البيانات الرجاء إعادة اختيار السؤال من الجدول ",
          });
        }
        if (!this.Questions.Name || !this.Questions.order) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال البيانات ",
          });
        }
        let state1 = false;
        this.Questions.answers.forEach((element) => {
          if (element.IsCorrectAnswer == "true") {
            state1 = true;
          }
        });
        if (!state1) {
          return this.$message({
            type: "error",
            message: "عذراً. لم يتم إرفاق إجابة صحيحة مع السؤال....",
          });
        }
        this.$blockUI.Start();
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }

        if (this.Questions.file) {
          let form = new FormData();
          form.append("file", this.Questions.file);
          form.append("answers", JSON.stringify(this.Questions.answers));
          form.append("order", this.Questions.order);
          form.append("Name", this.Questions.Name);
          this.$http
            .QuestionUpdateWithFile(this.QuestionId, form, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "success",
                message: response.data.Message,
              });
              this.getQuestions(this.QuestionsId);
              this.Questions.Name = "";
              this.Questions.file = null;
              this.Questions.answers = [];
              this.Questions.order = "";
              this.questionsTempFile = null;
              this.QuestionId = null;
              this.$blockUI.Stop();
              if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
              }
              return;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        } else {
          this.$http
            .QuestionUpdateWithOutFile(
              this.QuestionId,
              this.Questions,
              reCAPTCHA
            )
            .then((response) => {
              this.$message({
                type: "success",
                message: response.data.Message,
              });
              this.Questions.file = "";
              this.Questions.answers = [];
              this.Questions.order = "";
              this.Questions.Name = "";
              this.questionsTempFile = null;
              this.QuestionId = null;
              this.$blockUI.Stop();
              this.getQuestions(this.QuestionsId);
              return;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
      } catch (error) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة المحاولة ",
        });
      }
    },

    EditQuestions(item) {
      this.QuestionId = item._id;
      this.questionsTempFile = item.filePath;
      this.Questions.Name = item.Name;
      this.Questions.answers = item.answers;
      this.Questions.order = item.order;
    },
    DeleteQuestionsFIle() {
      Swal.fire({
        title: `هل انت متأكد من عملية الحذف؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .DeleteQuestionsFIle(this.QuestionId, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.questionsTempFile = "";
              this.getQuestions(this.QuestionsId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    QuestionsPublish(item, status) {
      Swal.fire({
        title: `هل انت متأكد من عملية ${status ? "نشر" : "إيقاف النشر"}؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .QuestionPublish(item._id, { status: status }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.getQuestions(this.QuestionsId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    DeleteQuestions(item) {
      Swal.fire({
        title: "هـل انت متأكد من  الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .DeleteQuestions(item._id, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.getQuestions(this.QuestionsId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    async getQuestions(page = 1) {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      this.QuestionsInfo = [];
      let reCAPTCHA = await this.$recaptcha("login");
      this.Questions.pageNo = page;
      this.$http
        .Questions(
          this.QuestionsId,
          reCAPTCHA,
          this.Questions.pageNo,
          this.Questions.pageSize
        )
        .then((response) => {
          this.QuestionsInfo = response.data.info;
          this.Questions.pages = response.data.count;
          this.$blockUI.Stop();
          return;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    async SaveQuestion() {
      try {
        if (!this.Questions.Name || !this.Questions.order) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال البيانات ",
          });
        }
        let state1 = false;
        this.Questions.answers.forEach((element) => {
          if (element.IsCorrectAnswer == "true") {
            state1 = true;
          }
        });
        if (!state1) {
          return this.$message({
            type: "error",
            message: "عذراً. لم يتم إرفاق إجابة صحيحة مع السؤال....",
          });
        }
        this.$blockUI.Start();
        await this.$recaptchaLoaded();
        let reCAPTCHA = await this.$recaptcha("login");
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }

        if (this.Questions.file) {
          let form = new FormData();
          form.append("file", this.Questions.file);
          form.append("answers", JSON.stringify(this.Questions.answers));
          form.append("order", this.Questions.order);
          form.append("Name", this.Questions.Name);
          this.$http
            .QuestionWithFile(this.QuestionsId, form, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "success",
                message: response.data.Message,
              });
              this.getQuestions(this.QuestionsId);
              this.Questions.Name = "";
              this.Questions.file = null;
              this.Questions.answers = [];
              this.Questions.order = "";
              this.$blockUI.Stop();
              if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
              }
              return;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        } else {
          this.$http
            .QuestionWithOutFile(this.QuestionsId, this.Questions, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "success",
                message: response.data.Message,
              });
              this.Questions.file = "";
              this.Questions.answers = [];
              this.Questions.order = "";
              this.Questions.Name = "";
              this.$blockUI.Stop();
              this.getQuestions(this.QuestionsId);
              return;
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
      } catch (error) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة المحاولة ",
        });
      }
    },
    handleClose(index) {
      this.Questions.answers.splice(index, 1);
    },
    AddAnswer() {
      try {
        let state1 = false;
        if (this.Questions.answers.length == 6) {
          return this.$message({
            type: "info",
            message: "لقد وصلت الي الحد المطلوب... ",
          });
        }
        if (!this.answer) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال الإجابة ",
          });
        }

        this.Questions.answers.forEach((element) => {
          if (element.answer == this.answer) {
            state1 = true;
          }
        });

        if (state1) {
          return this.$message({
            type: "warn",
            message: "هذه الإجابة موجودة مسبقأ",
          });
        }

        this.Questions.answers.forEach((element) => {
          if (element.IsCorrectAnswer == "true") {
            state1 = true;
          }
        });
        if (this.IsCorrectAnswer == "true") {
          if (!state1) {
            this.Questions.answers.push({
              answer: this.answer,
              IsCorrectAnswer: this.IsCorrectAnswer,
            });
            this.IsCorrectAnswer = "false";
            this.answer = "";
            return;
          } else {
            this.$message({
              type: "warn",
              message:
                "تم إضافة اجابة صحيحة من قبل يمكنك حذف الإجابة الصحيحة وتعديلها مجدداً",
            });
          }
        } else {
          this.Questions.answers.push({
            answer: this.answer,
            IsCorrectAnswer: this.IsCorrectAnswer,
          });
          this.IsCorrectAnswer = "false";
          this.answer = "";
          return;
        }
      } catch (error) {
        return this.$message({
          type: "error",
          message: "الرجاء إعادة المحاولة ",
        });
      }
    },
    OpenQuestionsDialog(item) {
      this.QuestionsDialog = true;
      this.QuestionsId = item._id;
      this.QuestionId = null;
      this.Questions.file = null;
      this.Questions.answers = [];
      this.Questions.order = "";
      this.Questions.Name = "";
      this.$blockUI.Stop();
      this.getQuestions();
      if (this.$refs.upload) {
        this.$refs.upload.clearFiles();
      }
    },
    OpenExam() {
      this.ExamDialog = true;
      this.Exams.Name = "";
      this.Exams.order = "";
      this.Exams.description = "";
    },
    async SaveExam() {
      this.$blockUI.Start();
      if (!this.Exams.Name || !this.Exams.order || !this.Exams.description) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .CreateExam(this.$parent.EditAddItem?._id, this.Exams, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.GetVideo(this.form.pageNo);
          this.ExamDialog = false;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    videoFilesPublish(item, status) {
      Swal.fire({
        title: `هل انت متأكد من عملية ${status ? "نشر" : "إيقاف النشر"}؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .videoFilesPublish(item._id, { status: status }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.getFilesOfVideo(this.FileId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },
    videoFilesDelete(item) {
      Swal.fire({
        title: "هـل انت متأكد من  الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .videoFilesDelete(item._id, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.getFilesOfVideo(this.FileId);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    async UpdateFiles() {
      this.$blockUI.Start();
      if (
        !this.fileForm.Name ||
        !this.fileForm.order ||
        !this.fileForm.description ||
        !this.editFileId
      ) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      //
      if (this.fileForm.file) {
        let form = new FormData();
        form.append("file", this.fileForm.file);
        form.append("Name", this.fileForm.Name);
        form.append("order", this.fileForm.order);
        form.append("description", this.fileForm.description);
        this.$http
          .videoFilesWithFile(this.editFileId, form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "success",
              message: response.data.Message,
            });
            this.getFilesOfVideo(this.FileId);
            this.fileForm.file = "";
            this.fileForm.Name = "";
            this.fileForm.order = "";
            this.fileForm.description = "";
            this.editFileId = "";
            this.filePath = "";
            this.$blockUI.Stop();
            if (this.$refs.upload) {
              this.$refs.upload.clearFiles();
            }
            return;
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      } else {
        this.$http
          .videoFilesWithOutFile(this.editFileId, this.fileForm, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "success",
              message: response.data.Message,
            });
            this.getFilesOfVideo(this.FileId);
            this.fileForm.file = "";
            this.fileForm.Name = "";
            this.fileForm.order = "";
            this.fileForm.description = "";
            this.filePath = "";
            this.editFileId = "";
            this.$blockUI.Stop();
            if (this.$refs.upload) {
              this.$refs.upload.clearFiles();
            }

            return;
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
        //
      }
    },

    EditFile(item) {
      this.filePath = item.filePath;
      this.fileForm.Name = item.Name;
      this.fileForm.order = item.order;
      this.fileForm.description = item.description;
      this.editFileId = item._id;
    },
    async SaveFiles() {
      this.$blockUI.Start();
      if (
        !this.fileForm.file ||
        !this.fileForm.Name ||
        !this.fileForm.order ||
        !this.fileForm.description ||
        !this.FileId
      ) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      let form = new FormData();
      form.append("file", this.fileForm.file);
      form.append("Name", this.fileForm.Name);
      form.append("order", this.fileForm.order);
      form.append("description", this.fileForm.description);
      form.append("videoId", this.FileId);
      this.$http
        .AddVideoFile(form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.getFilesOfVideo(this.FileId);
          this.fileForm.file = "";
          this.fileForm.Name = "";
          this.fileForm.order = "";
          this.fileForm.description = "";
          this.$blockUI.Stop();
          if (this.$refs.upload) {
            this.$refs.upload.clearFiles();
          }
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },

    DeleteVideo() {
      Swal.fire({
        title: "هـل انت متأكد من الحذف ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .DeleteVideo(
              {
                id: this.Id,
                fileName: this.videoLink,
              },
              reCAPTCHA
            )
            .then((response) => {
              this.$blockUI.Stop();
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.videoDialog = false;
              this.GetVideo(this.form.pageNo);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenFiles(item) {
      this.FilesDialog = true;
      this.fileForm.file = "";
      this.fileForm.Name = "";
      this.fileForm.order = "";
      this.fileForm.description = "";
      this.FileId = item._id;
      this.filePath = "";
      (this.editFileId = ""), (this.FileInfo = []);
      this.getFilesOfVideo();
    },

    async getFilesOfVideo(page = 1) {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.fileForm.pageNo = page;
      this.$http
        .getFilesOfVideo(
          this.FileId,
          reCAPTCHA,
          this.fileForm.pageNo,
          this.fileForm.pageSize
        )
        .then((response) => {
          this.FileInfo = response.data.info;
          this.fileForm.pages = response.data.count;
          this.$blockUI.Stop();
          return;
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    CloseVideo() {
      this.FileName = "";
    },
    OpenVideo(item) {
      if (!item.videoState) {
        return this.$message({
          type: "error",
          message: "الملف قيد التحميل......",
        });
      }
      if (!item.InName) {
        return this.$message({
          type: "error",
          message: "لايوجد محاضرة لعرضها",
        });
      }
      var myVideo = document.getElementById("videoPlayer");
      var source = document.getElementById("sadasd");
      
      this.Name = item.Name;
      this.FileName =
        process.env.VUE_APP_URL +
        "runVideo/" +
        item.InName +
        ".mp4" +
        "/" +
        localStorage.getItem("Token");
      if (myVideo) {
        myVideo.pause();
      }
      if (source) {
        source.setAttribute("src", this.FileName);
        source.setAttribute("type", "video/mp4");
      }
      if (myVideo) {
        myVideo.load();
        myVideo.play();
      }
      if (document.getElementById("videoPlayer")) {
        document
          .getElementById("videoPlayer")
          .scrollIntoView({ block: "center" });
      }
    },
    async Save() {
      Swal.fire({
        title: `هل انت متأكد من إجراء عملية إضافة ؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          this.saveStatus = true;
          if (
            !this.form.file ||
            !this.form.Name ||
            !this.form.time ||
            !this.form.order ||
            !this.form.description
          ) {
            this.$blockUI.Stop();
            this.saveStatus = false;

            this.$message({
              type: "error",
              message: "الرجاء إدخال كافة الحقول ",
            });
            return;
          }
          if (this.form.file.size > 100000000) {
            this.$blockUI.Stop();
            this.saveStatus = false;

            this.$message({
              type: "error",
              message: "M100" + " حجم الملف يجب ان يكون اقل من  ",
            });

            return;
          }
          let name = (
            Math.random() +
            "-" +
            Math.random() +
            "-" +
            Math.random()
          ).toString();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          let form = {
            Name: this.form.Name,
            time: this.form.time,
            State: this.form.State,
            order: this.form.order,
            description: this.form.description,
            InName: name,
          };
          this.$http
            .AddVideo(this.$parent.EditAddItem?._id, form, reCAPTCHA)
            .then((response) => {
              this.$message({
                type: "success",
                message: response.data.Message,
              });
              this.GetVideo(this.form.pageNo);
              this.uploadFile(name);
              this.videoDialog = false;
              this.saveStatus = false;

              if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
              }
              this.$blockUI.Stop();
            })
            .catch((err) => {
              if (this.$refs.upload) {
                this.$refs.upload.clearFiles();
              }
              this.$blockUI.Stop();
              this.saveStatus = false;

              this.$message({
                type: "error",
                message: err.response.data.Message,
              });
            });
        }
      });
    },

    async Update() {
      Swal.fire({
        title: `هل انت متأكد من إجراء عملية التعديل ؟`,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.saveStatus = true;
          this.$blockUI.Start();
          if (
            !this.Id ||
            !this.form.Name ||
            !this.form.time ||
            !this.form.order ||
            !this.form.description
          ) {
            this.$blockUI.Stop();
            this.saveStatus = false;

            this.$message({
              type: "error",
              message: "الرجاء إدخال كافة الحقول ",
            });
            return;
          }
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          //
          if (this.form.file) {
            if (this.form.file.size > 100000000) {
              this.$blockUI.Stop();
              this.saveStatus = false;

              this.$message({
                type: "error",
                message: "M100" + " حجم الملف يجب ان يكون اقل من  ",
              });
              return;
            }
            let name = (
              Math.random() +
              "-" +
              Math.random() +
              "-" +
              Math.random()
            ).toString();
            let form = {
              Name: this.form.Name,
              time: this.form.time,
              State: this.form.State,
              order: this.form.order,
              description: this.form.description,
              InName: name,
            };
            this.$http
              .VideoUpdateWithFile(this.Id, form, reCAPTCHA)
              .then((response) => {
                this.$message({
                  type: "info",
                  message: response.data.Message,
                });
                if (this.form.file) {
                  this.uploadFile(name);
                }
                this.GetVideo(this.form.pageNo);
                this.videoDialog = false;
                this.form.file = "";
                this.$blockUI.Stop();
                this.saveStatus = false;

                if (this.$refs.upload) {
                  this.$refs.upload.clearFiles();
                }
                return;
              })
              .catch((err) => {
                if (this.$refs.upload) {
                  this.$refs.upload.clearFiles();
                }
                this.$blockUI.Stop();
                this.saveStatus = false;
                this.$message({
                  type: "error",
                  message: err.response.data.Message,
                });
              });
          } else {
            this.$http
              .VideoUpdateWithOut(this.Id, this.form, reCAPTCHA)
              .then((response) => {
                this.$message({
                  type: "info",
                  message: response.data.Message,
                });

                this.GetVideo(this.form.pageNo);
                this.videoDialog = false;
                this.$blockUI.Stop();
                this.saveStatus = false;

                return;
              })
              .catch((err) => {
                this.$blockUI.Stop();
                this.saveStatus = false;

                this.$message({
                  type: "error",
                  message: err.response.data.Message,
                });
              });
            //
          }
        }
      });
    },

    FileChangedFiles(e) {
      // if (this.fileForm.file) {
      //   if (this.$refs.upload) {
      //     this.$refs.upload.clearFiles();
      //   }
      // }
      this.fileForm.file = e.raw;
    },
    FileChangedFileQuestions(e) {
      if (this.Questions.file) {
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      }
      this.Questions.file = e.raw;
    },
    FileChanged(e) {
      if (this.form.file) {
        if (this.$refs.upload) {
          this.$refs.upload.clearFiles();
        }
      }
      this.form.file = e.raw;

      // this.ruleForm.fileList.push(e.raw);
    },
    async GetVideo(page = 1) {
      this.form.pageNo = page;
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetVideos(
          this.$parent.EditAddItem?._id,
          this.form.pageNo,
          this.form.pageSize,
          reCAPTCHA
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.form.pages = response.data.count;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return this.$router.replace("Login");
          }
          this.$blockUI.Stop();
        });
    },

    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من تعطيل النشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeStateOfVideo(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetVideo(this.form.pageNo);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envUnit() + id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  نشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeStateOfVideo(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetVideo(this.form.pageNo);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    Edit(item) {
      this.Id = item._id;
      this.form.Name = item.Name;
      this.form.time = item.time;
      this.form.State = item.State;
      this.form.order = item.order;
      this.form.description = item.description;
      this.videoLink = item.InName;
      this.form.IsTest = item.IsTest;
      this.IsTest = item.IsTest;
      this.fileForm.file = null;
      if (this.IsTest) {
        this.title = "إختبار";
      } else {
        this.title = "محاضرة";
      }
      this.form.CanPass = item.CanPass;
      this.videoDialog = true;
      if (this.IsTest) {
        this.title = "إختبار";
      } else {
        this.title = "محاضرة";
      }
    },

    Addpage() {
      this.Id = "";
      this.form.Name = "";
      this.form.time = "";
      this.form.State = "";
      this.form.order = "";
      this.form.description = "";
      this.videoLink = "";
      this.videoDialog = true;
    },
  },
};
