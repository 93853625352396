export default {
  name: "EditAdd",
  created() {
    this.form.ParentName = this.$parent.EditAddItem.ParentName;
    this.form.ParentPhoneNumber = this.$parent.EditAddItem.ParentPhoneNumber;
    this.form.SecondParentPhoneNumber =
      this.$parent.EditAddItem.SecondParentPhoneNumber;
    // this.form.ParentTempPassword = this.$parent.EditAddItem.ParentTempPassword;
    this.form.ParentIdNumber = this.$parent.EditAddItem.ParentIdNumber;
  },
  data() {
    return {
      activeNames: "1",
      form: {
        ParentName: null,
        ParentPhoneNumber: null,
        SecondParentPhoneNumber: null,
        ParentTempPassword: null,
        ParentIdNumber: null,
      },
      activeClass: "js-active position-relative",
      activeStep: 1,
      formSteps: 3,
      step: 0,
    };
  },
  methods: {
    
    GeneratePassword2() {
      this.form.ParentTempPassword = Math.random().toString(36).slice(-8);
    },
    
    submitForm() {
        if (!this.form.ParentName) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال  الاسم",
          });
        }
        if (!this.form.ParentTempPassword) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال  الرمز السري",
          });
        }

        if (!this.form.ParentIdNumber) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال  اثبات الهوية",
          });
        }

        if (!this.form.ParentPhoneNumber) {
          return this.$message({
            type: "error",
            message: "الرجاء رقم الهاتف ",
          });
        } else if (!this.validPhone(this.form.ParentPhoneNumber)) {
          return this.$message({
            type: "error",
            message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
          });
        }

        if (this.form.SecondParentPhoneNumber) {
          if (!this.validPhone(this.form.SecondParentPhoneNumber)) {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
            });
            return;
          }
        }
      this.Save();
    },
    Back() {
      this.$parent.state = 0;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validLoginName: function (LoginName) {
      var login = /^[a-zA-Z]{0,40}$/;
      return login.test(LoginName);
    },
    validFullName: function (Name) {
      var loginArabic = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
      return loginArabic.test(Name);
    },

    validPassword: function (Password) {
      var PasswordT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/;

      return PasswordT.test(Password);
    },
    validPhone: function (Phone) {
      var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;

      return mobileRegex.test(Phone);
    },
    FileChanged(e) {
      this.form.PhotoFullPath = e.raw;
      // this.ruleForm.fileList.push(e.raw);
    },
    async Save() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        return this.$http
          .updateParents(this.$parent.EditAddItem._id, this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      this.$http
        .AddParents(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
           
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
