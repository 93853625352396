import Swal from "sweetalert2";
import moment from "moment";
export default {
  name: "Index",
  created() {
    this.GetInfo();
  },

  components: {},

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      amount: 0,
      form: {
        textRequestEndSubscription: "",
        id: "",
      },
      otp: "",
      ShowOTP: false,
      TotalValue: 0,
      personage: 0,
      countSubscription: [],
      objectItem: "",
      RequestEndSubscription: false,
      srcImage: this.$http.envSubject(),
      Categories: [],
      SubCategories: [],
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Category: "",
      SubCategory: "",
      EditAddItem: [],
      Status: false,
    };
  },
  watch: {
    Category() {
      this.SubCategory = "";
      if (this.Category) {
        this.GeActiveSubCategory();
      }
    },
    SubCategory() {
      if (this.SubCategory) {
        this.GetInfo(1);
      }
    },
  },
  methods: {
    async GeActiveSubCategory() {
      this.SubCategories = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveSubCategory(this.Category, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.SubCategories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
        });
    },
    async GetInfo(pageNo = 1) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetCashOut(this.pageNo, this.pageSize, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
          this.amount = response.data.amount;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        });
    },
  },
};
