export default {
  name: "EditAdd",
  created() {
    this.GetInfo(1);
    this.form.Id = this.$parent.EditAddItem?._id;
    this.form.Name = this.$parent.EditAddItem?.Name;
    this.form.CompleteHoures = this.$parent.EditAddItem?.CompleteHoures;
    this.form.Description = this.$parent.EditAddItem?.Description;
    this.form.PackageFullPrice = this.$parent.EditAddItem?.PackageFullPrice;
    this.form.Teacher = this.$parent.EditAddItem?.Teacher._id;
    this.form.YearOfStudy = this.$parent.EditAddItem?.YearOfStudy;
    this.form.PhotoFullPath = this.$parent.EditAddItem?.PhotoFullPath;
    this.form.Category = this.$parent.EditAddItem?.Category;
  },
  data() {
    return {
      Info:[],
      form: {
        Category:false,
        Name: "",
        CompleteHoures: "",
        Description: "",
        PackageFullPrice: "",
        Teacher: "",
        YearOfStudy: "",
        PhotoFullPath: "",
      },
    };
  },
  watch:{
    'form.Category'(){
      this.form.YearOfStudy = ""
    },
  },
  methods: {
    async GetInfo() {
      this.Info = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetTeachers(1, 200, reCAPTCHA, false)
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
          this.pages = 0;
        });
    },
    Back() {
      this.$parent.state = 0;
    },

    FileChanged(e) {
      this.form.PhotoFullPath = e.raw;
    },
    async Save() {
      this.$blockUI.Start();
      if (
        !this.form.Name ||
        !this.form.CompleteHoures ||
        !this.form.Description ||
        !this.form.PackageFullPrice ||
        !this.form.Teacher ||
        !this.form.YearOfStudy ||
        !this.form.PhotoFullPath
      ) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }

      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        let form = new FormData();
        form.append("Name", this.form.Name);
        form.append("CompleteHoures", this.form.CompleteHoures);
        form.append("Description", this.form.Description);
        form.append("PackageFullPrice", this.form.PackageFullPrice);
        form.append("Teacher", this.form.Teacher);
        form.append("YearOfStudy", this.form.YearOfStudy);
        form.append("PhotoFullPath", this.form.PhotoFullPath);
        form.append("Category",this.form.Category)
        return this.$http
          .updateSubject(this.$parent.EditAddItem._id, form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      let form = new FormData();
      form.append("Name", this.form.Name);
      form.append("CompleteHoures", this.form.CompleteHoures);
      form.append("Description", this.form.Description);
      form.append("PackageFullPrice", this.form.PackageFullPrice);
      form.append("Teacher", this.form.Teacher);
      form.append("YearOfStudy", this.form.YearOfStudy);
      form.append("PhotoFullPath", this.form.PhotoFullPath);
      this.$http
        .AddSubject(form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
           
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
