import Chart from "primevue/chart";

export default {
  name: "home",
  components: {
    Chart: Chart,
  },

  created() {
    window.scrollTo(0, 0);
    this.GetInfo();
  },
  data() {
    return {
      LastSubscription: [],
      SubscriptionCount: 0,
      SubjectCount: 0,
      ActiveSubjects: null,
      NotActiveSubjects: 0,
      CashOutSubscriptions: 0,
      CashedOutSubscriptions: 0,
      ActiveSubscriptions: 0,
      EndedSubscriptions: 0,
      PriceChangeCount: 0,
      CommentsCount: 0,
      TotalPrice: 0,
      chartData: {
        labels: ["مواد تم نشرها", "لم يتم نشرها"],
        datasets: [
          {
            data: [0, 0],
            backgroundColor: ["#42A5F5", "#FFA726"],
            hoverBackgroundColor: ["#64B5F6", "#FFB74D"],
          },
        ],
      },
      chartOptions: {
        legend: {
          labels: {
            fontColor: "#495057",
          },
        },
      },
    };
  },
  methods: {
    async GetInfo() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetStatistics(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.chartData.datasets[0].data[0] = response.data.ActiveSubjects;
          this.chartData.datasets[0].data[1] = response.data.NotActiveSubjects;
          this.LastSubscription = response.data.LastSubscription;
          this.SubscriptionCount = response.data.SubscriptionCount;
          this.SubjectCount = response.data.SubjectCount;
          this.ActiveSubjects = response.data.ActiveSubjects;
          this.NotActiveSubjects = response.data.NotActiveSubjects;
          this.CashOutSubscriptions = response.data.CashOutSubscriptions;
          this.CashedOutSubscriptions = response.data.CashedOutSubscriptions;
          this.ActiveSubscriptions = response.data.ActiveSubscriptions;
          this.EndedSubscriptions = response.data.EndedSubscriptions;
          this.PriceChangeCount = response.data.PriceChangeCount;
          this.CommentsCount = response.data.CommentsCount;
          this.TotalPrice = response.data.TotalPrice;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
        });
    },
  },
};
