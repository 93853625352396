import VueRouter from "vue-router";

/////////////////////////////////   Sales   /////////////////////////////////////////////
import Layout from "./components/Layout/Layout.vue";
import profile from "./components/Sales/Profile/Index.vue";
import Login from "./components/Sales/Login/Login.vue";
import ResetPassword from "./components/Sales/ResetPassword/Login.vue";
import Home from "./components/Home/Home.vue";
import Teachers from "./components/Sales/Teachers/Index.vue";
import Accounts from "./components/Sales/Accounts/Index.vue";
import Parents from "./components/Sales/Parents/Index.vue";
import Invoices from "./components/Sales/invoices/Index.vue";
import Category from "./components/Sales/Category/Index.vue";
import VIPList from "./components/Sales/VIPList/Index.vue";
import SubCategory from "./components/Sales/SubCategory/Index.vue";
import EndSubscription from "./components/Sales/EndSubscription/Index.vue";
import Subject from "./components/Sales/Subject/Index.vue";
import Units from "./components/Sales/Unit/Index.vue";
import Users from "./components/Sales/Users/Users.vue";
import reports from "./components/Reports/Reports.vue";
import Groups from "./components/Sales/Groups/index.vue";
import FrequentlyAskedQuestions from "./components/Sales/FrequentlyAskedQuestions/Index.vue";
import PageNotFound from "./components/PageNotFound/Index.vue";
import Distributor from "./components/Sales/Distributor/Index.vue";
import Orders from "./components/Sales/Orders/Index.vue";
import CashOut from "./components/Sales/CashOut/Index.vue";
import CashOutReport from "./components/Sales/CashOutReport/Index.vue";
import CashOutTeacher from "./components/Sales/CashOutTeacher/Index.vue";
import SubjectStudents from "./components/Sales/SubjectStudents/Index.vue";
import ShowPriceChange from "./components/Sales/ShowPriceChange/Index.vue";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/PageNotFound",
    component: PageNotFound,
    meta: { isAllowd: true },
  },

  {
    path: "/Login",
    component: Login,
  },
  {
    path: "/ResetPassword/:token/:state",
    name: "reset",
    component: ResetPassword,
  },
  {
    path: "",
    component: Layout,
    children: [
      {
        path: "/VIPList",
        name: "VIP",
        component: VIPList,
        meta: { isAllowd: true },
      },
      {
        path: "/profile",
        name: "الحســـاب الشخصــي",
        component: profile,
        meta: { isAllowd: true },
      },
      {
        path: "/CashOutReport",
        name: "Cash Out Report",
        component: CashOutReport,
        meta: { isAllowd: true },
      },

      {
        path: "/FrequentlyAskedQuestions",
        name: "الاسئــلة الشائعة",
        component: FrequentlyAskedQuestions,
        meta: { isAllowd: true },
      },

      {
        path: "/SubjectStudents",
        name: "اشتركات الطلبة",
        component: SubjectStudents,
        meta: { isAllowd: true },
      },
      {
        path: "/ShowPriceChange",
        name: "سجل تغيرات الاسعار",
        component: ShowPriceChange,
        meta: { isAllowd: true },
      },
      {
        path: "/Invoices",
        name: "الفواتــير",
        component: Invoices,
        meta: { isAllowd: true },
      },
      {
        path: "/CashOut",
        name: "Cash Out",
        component: CashOut,
        meta: { isAllowd: true },
      },
      {
        path: "/Users",
        name: "المستخدمين",
        component: Users,
        meta: { isAllowd: true },
      },
      {
        path: "/Teachers",
        name: "الاســاتذة",
        component: Teachers,
        meta: { isAllowd: true },
      },

      {
        path: "/Subject",
        component: Subject,
        name: "المــواد",
        meta: { isAllowd: true },
      },
      {
        path: "/Units/:id",
        component: Units,
        name: "الوحـــدات",
        meta: { isAllowd: true },
      },
      {
        path: "/Accounts",
        component: Accounts,
        name: "حســــابات الطلــبة",
        meta: { isAllowd: true },
      },
      {
        path: "/CashOutTeacher",
        component: CashOutTeacher,
        name: "تقرير سحب الارباح",
        meta: { isAllowd: true },
      },

      {
        path: "/Groups",
        component: Groups,
        name: "الصلاحيـــات",
        meta: { isAllowd: true },
      },
      {
        path: "/EndSubscriptions",
        name: "انهاء الإشتراكـــات",
        component: EndSubscription,
        meta: { isAllowd: true },
      },

      {
        path: "/Home",
        component: Home,
        name: "الرئيسيــة",
        meta: { isAllowd: true },
      },
      {
        path: "/Parents",
        component: Parents,
        name: "اولياء الامــور",
        meta: { isAllowd: true },
      },
      {
        path: "reports",
        component: reports,
        name: "التقاريــر",
        meta: { isAllowd: true },
      },
      {
        path: "Category",
        component: Category,
        name: "التصنيفــات",
        meta: { isAllowd: true },
      },
      {
        path: "SubCategory",
        name: "التصنيفــات الفرعية",
        component: SubCategory,
        meta: { isAllowd: true },
      },
      {
        path: "Orders",
        name: "طلبيات",
        component: Orders,
        meta: { isAllowd: true },
      },

      {
        path: "Distributor",
        name: "الموزعين",
        component: Distributor,
        meta: { isAllowd: true },
      },
    ],
  },
  {
    path: "*",
    redirect: "PageNotFound",
  },
];
const router = new VueRouter({
  routes,
});

export default router;
