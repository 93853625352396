/////////////////////////////////   Sales   /////////////////////////////////////////////
import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import Vuetify from "vuetify";
import locale from "element-ui/lib/locale/lang/en";
import BlockUIService from "./Shared/BlockUIService.js";
import router from "./router.js";
import VueRouter from "vue-router";
import App from "./App.vue";
import DataService from "./Shared/DataService";
import Helper from "./Shared/Helper";
import "../node_modules/nprogress/nprogress.css";
import PrimeVue from 'primevue/config';
import { VueReCaptcha } from "vue-recaptcha-v3";
import axios from "axios";
axios.defaults.withCredentials = true;
import excel from "vue-excel-export";
Vue.use(excel);
Vue.use(PrimeVue);
Vue.use(VueReCaptcha, { siteKey: "6Lda9xElAAAAALtIZWprSOdQ5doqXVO7afJi-qgy" });
Vue.use(Vuetify);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(ElementUI, { locale });
Vue.config.productionTip = false;
Vue.prototype.$http = DataService;
Vue.prototype.$blockUI = BlockUIService;
Vue.prototype.$helper = Helper;
export const eventBus = new Vue();
Vue.filter("toUpperCase", function (value) {
  if (!value) return "";
  return value.toUpperCase();
});

router.beforeEach((to, from, next) => {
  console.log(to.fullPath);
  if (to.name) {
    if (to.name == "reset") {
      next();
      return;
    }
  }
 
  if (to.fullPath !== "/login") {
    if (!localStorage.getItem("Token")) {
      return next({ path: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});



// document.addEventListener("contextmenu", (e) => e.preventDefault());

// function ctrlShiftKey(e, keyCode) {
//   return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
// }

// document.onkeydown = (e) => {
//   // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
//   if (
//     event.keyCode === 123 ||
//     ctrlShiftKey(e, "I") ||
//     ctrlShiftKey(e, "J") ||
//     ctrlShiftKey(e, "C") ||
//     (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
//   )
//     return false;
// };

new Vue({
  router,
  render: (h) => {
    return h(App);
  },
}).$mount("#cpanel-management");
