export default {
  name: "EditAdd",
  created() {
    // this.GeActiveCategory();
    this.form.Id = this.$parent.EditAddItem?._id;
    this.form.Name = this.$parent.EditAddItem?.Name;
    this.form.CompleteHoures = this.$parent.EditAddItem?.CompleteHoures;
    this.form.Description = this.$parent.EditAddItem?.Description;
    this.form.order = this.$parent.EditAddItem?.order;
  },
  data() {
    return {
      ruleForm: {},
      rules: {},
      Info: [],
      Categories: [],
      SubCategories: [],
      Subjects: [],
      form: {
        Category: "",
        Name: "",
        CompleteHoures: "",
        Description: "",
        // Price: "",
        PhotoFullPath: "",
        Subject: this.$parent.Subject
      },
    };
  },
  watch: {
    // "form.Category"() {
    //   this.form.SubCategory = "";
    //   this.form.Subject = "";
    //   if (this.form.Category) {
    //     this.GeActiveSubCategory();
    //   }
    // },
    // "form.SubCategory"() {
    //   if (this.form.SubCategory) {
    //     this.GetSubjects();
    //   }
    //   this.form.Subject = "";
    // },
  },
  methods: {
    
    Back() {
      this.$parent.state = 0;
    },
    FileChanged(e) {
      this.form.PhotoFullPath = e.raw;
    },
    async Save() {
      this.$blockUI.Start();
      if (
        !this.form.Name ||
        !this.form.CompleteHoures ||
        !this.form.Description ||
        !this.form.Subject||
        !this.form.order
      ) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
        return;
      }
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        let form = new FormData();
        form.append("Name", this.form.Name);
        form.append("CompleteHoures", this.form.CompleteHoures);
        form.append("Description", this.form.Description);
        form.append("order", this.form.order);
        form.append("Subject", this.form.Subject);
        // form.append("SubCategory", this.form.SubCategory);
        form.append("PhotoFullPath", this.form.PhotoFullPath);
        // form.append("Category", this.form.Category);
        return this.$http
          .updateUnits(this.$parent.EditAddItem._id, form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.state = 0;
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      let form = new FormData();
      form.append("Name", this.form.Name);
      form.append("CompleteHoures", this.form.CompleteHoures);
      form.append("Description", this.form.Description);
      form.append("order", this.form.order);
      form.append("Subject", this.form.Subject);
      // form.append("SubCategory", this.form.SubCategory);
      form.append("PhotoFullPath", this.form.PhotoFullPath);
      // form.append("Category", this.form.Category);
      this.$http
        .AddUnit(form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    handleRemoveFile() {},
    beforeRemove() {},
    onBeforeUpload() {},
    handleExceed() {},

    // async GeActiveSubCategory() {
    //   this.SubCategories = [];
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .GeActiveSubCategory(this.form.Category, reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.SubCategories = response.data.info;
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //     });
    // },
    // async GeActiveCategory() {
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .GeActiveCategory(reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.Categories = response.data.info;
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //       this.$blockUI.Stop();
    //     });
    // },
    
    // async GetSubjects() {
    //   this.$blockUI.Start();
    //   await this.$recaptchaLoaded();
    //   let reCAPTCHA = await this.$recaptcha("login");
    //   this.$http
    //     .AllSubjects(this.form.SubCategory, reCAPTCHA)
    //     .then((response) => {
    //       this.$blockUI.Stop();
    //       this.Subjects = response.data.info;
    //       // if (firstTimeOnly) {
    //         // this.form.Subject = this.$parent.EditAddItem?.Subject._id;
    //       // }

    //       // this.Subject = this.Subjects[0]._id;
    //       // if (this.Subjects.length ==1) {
    //       //   this.Subject = this.Subjects[0]._id;
    //       // }
    //     })
    //     .catch((error) => {
    //       if (error.response.status == 401) {
    //         return  window.location = "/"
    //       }
    //       this.$blockUI.Stop();
    //     });
    // },
  },
};
