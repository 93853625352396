import moment from "moment";
export default {
  name: "Index",
  created() {
    this.GetInfo();
    this.Name = localStorage.getItem("name");
  },
  components: {},
  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      return moment(date).format("MMMM Do YYYY");
    },
  },
  data() {
    return {
      Name: "",
      showImage: false,
      image: "",
      link:'',
      Description: "",
      imageSrc:this.$http.envTeachers(),
    };
  },
  methods: {
    async GetInfo() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetTesterProfile(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Name = response.data.info.Name;
          this.Description = response.data.info.Description;
          this.link =  response.data.info.PhotoFullPath;
          localStorage.setItem('image',response.data.info.PhotoFullPath);
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
        });
    },
    handleExceed() {},
    onBeforeUpload() {},
    beforeRemove() {},
    handleRemoveFile() {},
    FileChanged(e) {
      this.image = e.raw;
    },
    async SaveImage() {
      if (!this.image) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال كافة الحقول ",
        });
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      let from = new FormData();
      from.append("image", this.image);
      from.append("Description", this.Description);
      this.$http
        .TeacherUpdateProfile(from, reCAPTCHA)
        .then(() => {
          this.$blockUI.Stop();
          this.showImage = false;
          this.$refs.upload.clearFiles();
          this.GetInfo();
          return this.$message({
            type: "success",
            message: "تم الحقظ بنجاح",
          });
        })
        .catch((error) => {
          this.$blockUI.Stop();
          return this.$message({
            type: "error",
            message: error.response.data.Message,
          });
        });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = this.$http.envTeachers() + id;
    },
  },
};
