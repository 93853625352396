export default {
  name: "EditAdd",
  created() {
    this.GetParents();
    this.form.ParentName = this.$parent.EditAddItem.ParentName;
    this.form.ParentPhoneNumber = this.$parent.EditAddItem.ParentPhoneNumber;
    this.form.SecondParentPhoneNumber =
      this.$parent.EditAddItem.SecondParentPhoneNumber;
    this.form.ParentId = this.$parent.EditAddItem.ParentId;
  },
  data() {
    return {
      activeNames: "1",
      Parents: [],
      form: {
        StudentName: null,
        StudentTempPassword: null,
        StudentIdNumber: null,
        StudentPhoneNumber: null,
        SecondStudentPhoneNumber: null,
        ParentId: null,
        /////
        ParentName: null,
        ParentPhoneNumber: null,
        SecondParentPhoneNumber: null,
        ParentTempPassword: null,
        ParentIdNumber: null,
      },
      OpenModal: false,
      activeClass: "js-active position-relative",
      activeStep: 0,
      formSteps: 3,
      step: 0,
    };
  },
  methods: {
    GeneratePassword2() {
      this.form.ParentTempPassword = Math.random().toString(36).slice(-8);
    },
    ShowModal() {
      this.OpenModal = true;
    },
    async SaveParents() {
      if (!this.form.ParentName) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  الاسم",
        });
      }
      if (!this.form.ParentTempPassword) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  الرمز السري",
        });
      }

      if (!this.form.ParentIdNumber) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  اثبات الهوية",
        });
      }

      if (!this.form.ParentPhoneNumber) {
        return this.$message({
          type: "error",
          message: "الرجاء رقم الهاتف ",
        });
      } else if (!this.validPhone(this.form.ParentPhoneNumber)) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
        });
      }

      if (this.form.SecondParentPhoneNumber) {
        if (!this.validPhone(this.form.SecondParentPhoneNumber)) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
          });
          return;
        }
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .AddParents(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.GetParents();
          this.OpenModal = false;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    async GetParents() {
      this.Parents = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetParentsAll(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Parents = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return  window.location = "/"
          }
          this.$blockUI.Stop();
        });
    },
    GeneratePassword() {
      this.form.StudentTempPassword = Math.random().toString(36).slice(-8);
    },
    // GeneratePassword2() {
    //   this.form.ParentTempPassword = Math.random().toString(36).slice(-8);
    // },
    prevStep() {
      this.activeStep = 0;
    },
    submitForm() {
      if (!this.form.StudentName) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  اسم الطالب",
        });
      }
      if (!this.form.StudentTempPassword) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  الرمز السري",
        });
      }

      if (!this.form.StudentIdNumber) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال  اثبات الهوية",
        });
      }

      if (!this.form.StudentPhoneNumber) {
        return this.$message({
          type: "error",
          message: "الرجاء رقم الهاتف ",
        });
      } else if (!this.validPhone(this.form.StudentPhoneNumber)) {
        return this.$message({
          type: "error",
          message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
        });
      }

      if (this.form.SecondStudentPhoneNumber) {
        if (!this.validPhone(this.form.SecondStudentPhoneNumber)) {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
          });
          return;
        }
      }
      if (!this.form.ParentId) {
        this.$blockUI.Stop();
        this.$message({
          type: "error",
          message: "الرجاء اختيار ولي الأمر ",
        });
        return;
      }

      // if (nextStep == 2) {
      //   if (!this.form.ParentName) {
      //     return this.$message({
      //       type: "error",
      //       message: "الرجاء إدخال  اسم الطالب",
      //     });
      //   }
      //   if (!this.form.ParentTempPassword) {
      //     return this.$message({
      //       type: "error",
      //       message: "الرجاء إدخال  الرمز السري",
      //     });
      //   }

      //   if (!this.form.ParentIdNumber) {
      //     return this.$message({
      //       type: "error",
      //       message: "الرجاء إدخال  اثبات الهوية",
      //     });
      //   }

      //   if (!this.form.ParentPhoneNumber) {
      //     return this.$message({
      //       type: "error",
      //       message: "الرجاء رقم الهاتف ",
      //     });
      //   } else if (!this.validPhone(this.form.ParentPhoneNumber)) {
      //     return this.$message({
      //       type: "error",
      //       message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
      //     });
      //   }

      //   if (this.form.SecondParentPhoneNumber) {
      //     if (!this.validPhone(this.form.SecondParentPhoneNumber)) {
      //       this.$blockUI.Stop();
      //       this.$message({
      //         type: "error",
      //         message: "الرجاء إدخال رقم الهاتف  بطريقه صحيحه ",
      //       });
      //       return;
      //     }
      //   }
      // }
      // if (nextStep != 2) {
      //   return (this.activeStep = nextStep);
      // }
      this.Save();
    },
    Back() {
      this.$parent.state = 0;
    },
    validEmail: function (email) {
      var re =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validLoginName: function (LoginName) {
      var login = /^[a-zA-Z]{0,40}$/;
      return login.test(LoginName);
    },
    validFullName: function (Name) {
      var loginArabic = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
      return loginArabic.test(Name);
    },

    validPassword: function (Password) {
      var PasswordT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]){8,}.*$/;

      return PasswordT.test(Password);
    },
    validPhone: function (Phone) {
      var mobileRegex = /^09[1|2|3|4|5][0-9]{7}$/i;

      return mobileRegex.test(Phone);
    },
    FileChanged(e) {
      this.form.PhotoFullPath = e.raw;
      // this.ruleForm.fileList.push(e.raw);
    },
    async Save() {
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      if (this.$parent.EditAddItem) {
        return this.$http
          .updateAccount(this.$parent.EditAddItem._id, this.form, reCAPTCHA)
          .then((response) => {
            this.$message({
              type: "info",
              message: response.data.Message,
            });
            this.$parent.GetInfo(this.$parent.pageNo);
            this.$parent.state = 0;
             
            this.$blockUI.Stop();
          })
          .catch((err) => {
            this.$blockUI.Stop();
            this.$message({
              type: "error",
              message: err.response.data.Message,
            });
          });
      }
      this.$http
        .AddAccount(this.form, reCAPTCHA)
        .then((response) => {
          this.$message({
            type: "info",
            message: response.data.Message,
          });
          this.$parent.GetInfo(this.$parent.pageNo);
          this.$parent.state = 0;
           
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
  },
};
