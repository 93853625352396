import Swal from "sweetalert2";
import moment from "moment";
import Students from "./ShowPriceChange/Index.vue";
export default {
  name: "Index",
  created() {
    this.GetInfo();
  },

  components: {
    Students: Students,
  },

  filters: {
    moment: function (date) {
      if (date === null) {
        return "فارغ";
      }
      // return moment(date).format('MMMM Do YYYY, h:mm:ss a');
      return moment(date).format("MMMM Do YYYY");
    },
  },

  data() {
    return {
      form: {
        textRequestEndSubscription: "",
        id: "",
      },
      RequestEndSubscription: false,
      srcImage: this.$http.envSubject(),
      Categories: [],
      SubCategories: [],
      showImage: false,
      image: "",
      permissions: JSON.parse(localStorage.getItem("group")),
      Info: [],
      pageNo: 1,
      pageSize: 10,
      pages: 0,
      state: 0,
      Category: "",
      SubCategory: "",
      EditAddItem: [],
      Status: false,
    };
  },
  watch: {
    Category() {
      this.SubCategory = "";
      if (this.Category) {
        this.GeActiveSubCategory();
      }
    },
    SubCategory() {
      if (this.SubCategory) {
        this.GetInfo(1);
      }
    },
  },
  methods: {
    clearAndSearch() {
      this.SubCategory = "";
      this.Category = "";
      this.GetInfo(1);
    },
    handleClose() {},
    openDialog(item) {
      this.form.textRequestEndSubscription = "";
      this.form.Subject = item._id;
      this.RequestEndSubscription = true;
    },
    async SendRequest() {
      if (!this.form.textRequestEndSubscription) {
        return this.$message({
          type: "error",
          message: "الرحاء إدخال  الوصف",
        });
      }

      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      return this.$http
        .CreateEndSubscription(this.form, reCAPTCHA)
        .then(() => {
          this.$message({
            type: "info",
            message: "تم إرسال الطلب بنجاح.",
          });
          this.GetInfo();
          this.form.Subject = "";
          this.form.textRequestEndSubscription = "";
          this.RequestEndSubscription = false;
          this.$blockUI.Stop();
        })
        .catch((err) => {
          this.$blockUI.Stop();
          this.$message({
            type: "error",
            message: err.response.data.Message,
          });
        });
    },
    GoToUnit(id) {
      this.$router.push("/Units/" + id);
    },
    async GeActiveSubCategory() {
      this.SubCategories = [];
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveSubCategory(this.Category, reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.SubCategories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
        });
    },
    async GetInfo(pageNo = 1) {
      this.Info = [];
      this.pageNo = pageNo;
      if (this.pageNo === undefined) {
        this.pageNo = 1;
      }
      this.$blockUI.Start();
      await this.$recaptchaLoaded();
      let reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GetSubject(
          this.pageNo,
          this.pageSize,
          reCAPTCHA,
          this.SubCategory,
          this.Status
        )
        .then((response) => {
          this.$blockUI.Stop();
          this.Info = response.data.info;
          this.pages = response.data.count;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.pages = 0;
        });
      await this.$recaptchaLoaded();
      reCAPTCHA = await this.$recaptcha("login");
      this.$http
        .GeActiveCategory(reCAPTCHA)
        .then((response) => {
          this.$blockUI.Stop();
          this.Categories = response.data.info;
        })
        .catch((error) => {
          this.$blockUI.Stop();
          if (!error.response) {
            return this.$message({
              type: "error",
              message: "الرجاء التأكد من الإتصال بالانترنت",
            });
          }
          if (error.response.status == 401) {
            return (window.location = "/");
          }
          this.$blockUI.Stop();
        });
    },
    DActive(Id) {
      Swal.fire({
        title: "هـل انت متأكد من تعطيل النشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeSubjectStatus(Id, { status: true }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$helper.showWorning(err.response.data.Message);
            });
          return;
        }
      });
    },
    OpenImageDialog(id) {
      this.showImage = true;
      this.image = +id;
    },
    Activate(Id) {
      Swal.fire({
        title: "هـل انت متأكد من  نشر ؟",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `تأكيد العملية`,
        denyButtonText: `الغاء العملية`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$blockUI.Start();
          await this.$recaptchaLoaded();
          let reCAPTCHA = await this.$recaptcha("login");
          this.$http
            .ChangeSubjectStatus(Id, { status: false }, reCAPTCHA)
            .then((response) => {
              this.$blockUI.Stop();
              this.FormPorgress = 100;
              Swal.fire({
                icon: "success",
                title: "..نجـاح العملية",
                html: response.data.Message,
                showCancelButton: false,
                //confirmButtonText: `حـفظ`,
                denyButtonText: `خروج`,
              }).then(() => {});
              this.$blockUI.Stop();
              this.GetInfo(1);
            })
            .catch((err) => {
              this.$blockUI.Stop();
              this.$notify({
                title: "خطأ بعملية التفعيل",
                dangerouslyUseHTMLString: true,
                type: "error",
                message: err.response.data.Message,
              });
            });
          return;
        }
      });
    },

    Edit(item) {
      this.state = 2;
      this.EditAddItem = item;
    },
    openStudentPage(item) {
      this.state = 4;
      this.EditAddItem = item;
    },
    Addpage() {
      this.state = 2;
      this.EditAddItem = null;
    },
  },
};
